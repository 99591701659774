<script>
import Layout from "../../layouts/auth";
import appConfig from "@/app.config";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      user: "",
      password: "",
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      loading: false,
      version: appConfig.version,
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.service === 'offline') {
      this.isAuthError = true;
      this.authError = 'SISTEMA OFFLINE'
    }
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.tryingToLogIn = true;
      this.authError = null;
      this.isAuthError = false;
      this.loading = true;

      const { user, password } = this
      this.$store.dispatch('AUTH_REQUEST', { user, password }).then(() => {
        this.tryingToLogIn = false;
        this.isAuthError = false;

        this.$store.dispatch('USER_DATA').then((res) => {
          let firstRoute = 'users';

          if (res.permissions.users) firstRoute = 'users';
          else if (res.permissions.notifications) firstRoute = 'notifications';
          else if (res.permissions.banners) firstRoute = 'banners';
          else if (res.permissions.images) firstRoute = 'product-image';
          else if (res.permissions.paymentRules) firstRoute = 'payment-settings';
          else if (res.permissions.campaigns) firstRoute = 'campaigns/delivery';
          else if (res.permissions.permissions) firstRoute = 'permissions';
          else if (res.permissions.settings) firstRoute = 'settings';

          this.$router.push({ name: firstRoute });
          this.loading = false;
        }).catch(() => {
          this.$router.push({ name: "users" });
          this.loading = false;
        })
      }).catch(error => {
        if (error.response.data.code && error.response.data.code === 'E9999') {
          this.authError = error.response.data.msg;
        } else {
          this.authError = error ? error : "";
        }

        this.tryingToLogIn = false;        
        this.isAuthError = true;
        this.loading = false;
      })
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-5 col-xl-4">
        <div class="card overflow-hidden">
          <div class="bg-default">
            <div class="row img-login">
              <img src="@/assets/images/logo.png" alt class="img-fluid" />
              <!-- <div class="col-7">
                <div class="text-primary p-4">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p>Sign in to continue to Skote.</p>
                </div>
              </div>
              <div class="col-5 align-self-end">
                <img src="@/assets/images/profile-img.png" alt class="img-fluid" />
              </div> -->
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="mb-4">
              <!-- <router-link tag="a" to="/">
                <div class="avatar-md profile-user-wid mb-4">
                  <span class="avatar-title rounded-circle bg-light">
                    <img src="@/assets/images/logo.svg" alt height="34" />
                  </span>
                </div>
              </router-link> -->
            </div>

            <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{authError}}</b-alert>

            <b-form class="p-2" @submit.prevent="tryToLogIn">
              <b-form-group id="input-group-1" label="Usuário" label-for="input-1">
                <b-form-input id="input-1" v-model="user" type="text" size="lg"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-2" label="Senha" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="password"
                  type="password"
                  size="lg"
                ></b-form-input>
              </b-form-group>
              <!-- <div class="custom-control custom-checkbox">
                <input id="customControlInline" type="checkbox" class="custom-control-input" />
                <label class="custom-control-label" for="customControlInline">Remember me</label>
              </div> -->
              <div class="mt-4">
                <b-button type="submit" variant="primary" size="lg" class="btn-block" :disabled="loading">ENTRAR</b-button>
              </div>
              <!-- <div class="mt-4 text-center">
                <router-link tag="a" to="/forgot-password" class="text-muted">
                  <i class="mdi mdi-lock mr-1"></i> Forgot your password?
                </router-link>
              </div> -->
            </b-form>

            <div class="row" v-if="loading">
              <div class="col-12">
                <div class="text-center my-3">
                <a href="javascript:void(0);" class="text-primary">
                  <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
                </a>
                </div>
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="mt-2 text-center">
          <p>
            v{{version}} - Desenvolvido por <a href="https://mobwit.com.br" target="_blank">Mobwit</a>
          </p>
          <!-- <p>
            © {{new Date().getFullYear()}} Skote. Crafted with
            <i class="mdi mdi-heart text-danger"></i> by Themesbrand
          </p> -->
        </div>
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
